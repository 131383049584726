var report_pubblico = null;
var report = function (stop_init) {

    stop_init = stop_init || false;

    var report = this;

    this.c3_colors = [];
    this.c3_chart = null;
    this.c3_chart_visite_columns = [];
    this.c3_column_period = [];

    this.svg = null;
    this.provinces = null;
    this.svg_infobox = null;

    this.init = function () {

        $.get($("#svg_italia").attr("data-svgpath"), function (data) {

            /**
             * Loading svg ajax
             */
            var div = $("<div></div>");
            div.innerHTML = new XMLSerializer().serializeToString(data.documentElement);
            $("#svg_italia")[0].innerHTML = div.innerHTML;


            report.svg = $("#svg_italia svg");
            report.svg.svgPanZoom({
                events: {
                    mouseWheel: true, // enables mouse wheel zooming events
                    doubleClick: true, // enables double-click to zoom-in events
                    drag: true, // enables drag and drop to move the SVG events
                    dragCursor: "move" // cursor to use while dragging the SVG
                }
            });

            report.svg.css('width', '100%').css('height', '500px');
            report.bindEvents();

            report.provinces = report.svg.find(".province [id]");
            report.resetProvincesEvents();
            report.svg_infobox = $("[data-interaction=svg_infobox]");
        });

    };

    this.showSvg = function () {
        $("#svg_italia").show();
        $("#iframe_client").hide();
    };
    this.showIframe = function () {
        $("#svg_italia").hide();
        $("#iframe_client").show();
    };

    this.bindEvents = function () {

        $(".select2").select2({
            allowClear: true
        });

        $("#accordion_report").on('show.bs.collapse', function (obj) {
            var target = obj.target;
            $.each($("#accordion_report .panel-collapse"), function (index, panel) {
                if ($(panel).attr('id') != $(target).attr('id')) {
                    $(panel).collapse('hide');
                }
            });
        });

        $("[data-interaction=run_report]").unbind("click").bind("click", function () {
            var type = $(this).attr("data-report");
            var filters = {};
            var obj_btn = $(this);

            switch (type) {
                case 'monitoraggio_campagna':
                    report.showSvg();

                    filters = {
                        from: $('#date_from').val(),
                        to: $('#date_to').val(),
                        market_segment: $('#market_segment').val(),
                        product_id: $("#product_id").val()
                    };
                    break;
                case 'qualita_database':
                    report.showSvg();

                    filters = {
                        market_segment: $('#market_segment_3').val(),
                    };
                    break;
                case 'monitoraggio_visite':
                    report.showSvg();

                    filters = {
                        market_segment: $('#market_segment_visite').val(),
                        from: $('#date_from_visite').val(),
                        to: $('#date_to_visite').val(),
                        region: $('#region_visite').val(),
                        agent: $('#agent_visite').val(),
                    };
                    break;
                case 'monitoraggio_vendite':
                    report.showSvg();

                    if (($('#year_from_vendite').val() && !$('#month_from_vendite').val()) || ($('#year_to_vendite').val() && !$('#month_to_vendite').val())) {
                        app.warning("Seleziona il mese");
                        return;
                    }

                    filters = {
                        product_id: $('#product_id_vendite').val(),
                        from: ($('#year_from_vendite').val()) ? $('#year_from_vendite').val() + "-" + $('#month_from_vendite').val() + "-01" : undefined,
                        to: ($('#year_to_vendite').val()) ? $('#year_to_vendite').val() + "-" + $('#month_to_vendite').val() + "-01" : undefined
                    };
                    break;
                default:

            }

            var report_option = $(this).attr('data-reportoption') || undefined;

            if (report_option) {
                filters = $.extend(filters, {
                        report_options: report_option
                    }
                );
            }

            obj_btn.prop("disabled", true);
            obj_btn.find("[data-interaction=spinner]").show();

            report.executeReport(type, filters, function () {
                obj_btn.prop("disabled", false);
                obj_btn.find("[data-interaction=spinner]").hide();
            });
        });
    };

    /**
     * Reset provinces colors
     */
    this.resetProvincesColor = function () {
        report.provinces.css('fill', '#eee');
    };

    /**
     * Reset provinces events
     */
    this.resetProvincesEvents = function () {
        report.provinces.unbind('click').bind('click', report.resetSvgInfobox);
    };

    /**
     * Reset svg infobox
     */
    this.resetSvgInfobox = function () {
        report.svg_infobox.html('').hide();
    };

    /**
     * Reset legend
     */
    this.resetLegend = function () {
        $("[data-interaction=legend]").html("");
    };

    /**
     * Disegna la legenda
     */
    this.drawLegend = function (legend, selectorLegend) {
        var perc = 100 / legend.length;

        selectorLegend = selectorLegend || $("[data-interaction=legend]");

        report.resetLegend();

        $.each(legend, function (index, obj) {
            var background = obj.background;
            var text = obj.text === null ? "[" + obj.from + " - " + obj.to + "]" : obj.text;
            var title_color = typeof obj.color === 'undefined' ? 'white' : obj.color;

            selectorLegend.append($("<div class='progress-bar' style='width: " + perc + "%; background-color: " + background + "'><span style='color: " + title_color + "'>" + text + "</span></div>"));
        });
    };

    this.executeReport = function (tipo_report, r_data, callback) {
        callback = callback || undefined;
        r_data = r_data || undefined;

        /**
         * Applica lo stile alla provincia
         *
         * @param province short code
         * @param province province data
         */
        function setProvinceData(code, province) {
            var svg_province = report.svg.find(".province #p_" + code);

            if (typeof province.color !== 'undefined')
                svg_province.css("fill", province.color);


            svg_province.unbind('click').bind('click', function () {
                var tbl = $("<table class='table table-striped'></table>");
                tbl.append($("<tr><th colspan='2' class='text-center' style='font-size: 100%'><h5>" + province.title + "<br><small class='muted'>" + province.subtitle + "</small></h5>" + "</th></tr>"));

                if (typeof province.datasheet !== 'undefined') {
                    $.each(province.datasheet, function (nome, value) {
                        tbl.append($("<tr><th>" + nome + "</th><td class='text-right'>" + value + "</td></tr>"));
                    });
                }

                report.svg_infobox.html(tbl).show();
            });
        }

        report.resetProvincesColor();
        report.resetProvincesEvents();
        report.resetSvgInfobox();
        report.resetLegend();

        $.post_json(app.baseUrl + "/report/data", {report: tipo_report, data: r_data}, function (data) {
            if (data.response) {

                if (typeof data.legend != 'undefined')
                    report.drawLegend(data.legend);

                $.each(data.provinces, setProvinceData);
            } else {
                if (typeof data.message != 'undefined')
                    app.error(data.message);
                else
                    app.error('Errore');
            }

            if (typeof callback == 'function')
                callback();
        });
    };

    this.c3_period = function (c3_chart_selector) {


        var start = $("#start").val();
        var end = $("#end").val();


        var labelX = "giorni";
        var formatX = "%d/%m/%Y";
        var typeX = 'timeseries';

        app.block(1);
        $.post(app.baseUrl + '/reconciliation_report_data', {start:start, end:end}, function (data) {
            var it = d3.timeFormatDefaultLocale({
                "decimal": ",",
                "thousands": ".",
                "grouping": [3],
                "currency": ["€", ""],
                "dateTime": "%a, %e %b %Y - %X",
                "date": "%d/%m/%Y",
                "time": "%H:%M:%S",
                "periods": ["AM", "PM"],
                "days": ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
                "shortDays": ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
                "months": ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
                "shortMonths": ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"]
            });

            if (data.response) {
                report.c3_chart = c3.generate({
                    size: {height: 300},
                    bindto: c3_chart_selector,
                    data: {
                        x: 'x',
                        columns: data.columns,
                    },
                    legend: {
                        position: 'right',
                    },
                    axis: {
                        x: {
                            label: labelX,
                            type: typeX,
                            tick: {
                                format: formatX,
                            }
                        },
                        y: {
                            label: 'vendite',
                            tick: {
                                format: d3.format("s")
                            }
                        },
                    },
                    tooltip: {
                        format: {
                            // title: function (d) { return 'Data ' + d; },
                            value: function (value, ratio, id) {
                                return it.numberFormat(",.2$")(value);
                            }
                        }
                    }
                });

                report_pubblico = report;
                report.c3_column_period = data.location;

                report.c3_period_refresh_bind();
                app.block(0);


            } else {
                app.error(data.message);
                app.block(0);
            }

        });

    };

    this.c3_period_refresh_bind = function (c3_chart_selector) {
        $("[data-interaction=show_all_period]").unbind("click").bind("click", function () {
            $.each(report.c3_column_period, function (index, _obj) {
                report.c3_chart.show([_obj]);
            });

        });

        $("[data-interaction=hide_all_period]").unbind("click").bind("click", function () {
            $.each(report.c3_column_period, function (index, _obj) {
                report.c3_chart.hide([_obj]);
            });

        });

    };

    if (!stop_init) {
        this.init();
    }

};
