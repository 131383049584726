var product = function () {

    var product = this;

    this.bindEvents = function () {
        // recupero l'id del prodotto corrente
        var idProduct = $("#frmProduct").attr("data-id");

        tinymce.init({
            selector: "textarea",
            theme: "modern",
            height: 300,
            language: 'it',
            paste_data_images: true,
            plugins: [
                "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                "searchreplace wordcount visualblocks visualchars code fullscreen",
                "insertdatetime media nonbreaking save table contextmenu directionality",
                "template paste textcolor colorpicker textpattern"
            ],
            toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
            toolbar2: "print preview media | forecolor backcolor fontsizeselect",
            fontsize_formats: '11px 17px 22px',
            init_instance_callback: function (editor) {
                editor.on('Change', function (e) {
                    $("#" + editor.id).val(editor.getContent());
                });
            },
            setup : function(ed)
            {
                ed.on('init', function()
                {
                    this.getDoc().body.style.fontSize = '17px';
                });
            }
        });

        $("#experience").on("change", function () {
            if ($(this).is(":checked")) {
                $(".location").hide();
            } else {
                $(".location").show();
            }
        });

        if ($("#gallery").length > 0) {
            // c'è l'elemento e allora gli applico il DropZone sopra
            $("#gallery").dropzone({
                url: app.baseUrl + "/product/" + idProduct + "/product_media",
                headers: $.ajaxSetup().headers,
                addRemoveLinks: true,
                dictRemoveFile: $("#gallery").attr('data-remove'),
                dictRemoveFileConfirmation: T.uploadRemove,
                maxFiles: 20,
                clickable: true,
                maxFilesize: $("#gallery").attr('data-max'),
                acceptedFiles: 'image/*',
                init: function () {
                    var medias = JSON.parse($("#medias").val());

                    if (typeof medias.ProductMedias !== 'undefined' && medias.ProductMedias.length > 0) {
                        medias = medias.ProductMedias;

                        var self = this;
                        $.each(medias, function () {
                            if (typeof this.Id !== 'undefined') {
                                // oggetto file per dropzone
                                var file = {
                                    name: this.Image,
                                    size: this.Size,
                                    status: 'success',
                                    id: this.Id,
                                    accepted: true
                                };

                                // fingo di aggiungere un nuovo documento a dropzone
                                self.emit("addedfile", file);
                                self.createThumbnailFromUrl(file, app.siteUrlNotLocalized + "/storage/" + this.Image);
                                self.emit("complete", file);

                                // aggiungo il mio file all'array
                                self.files.push(file);
                            }
                        });
                    }
                },
                sending: function (file, data) {
                    $("#spinner").show();
                },
                success: function (file, data) {
                    $("#spinner").hide();
                    if (!data.response) {
                        app.warning("", data.message);
                        this.removeFile(file);
                    } else {
                        file.id = data.message;
                    }
                },
                error: function (file, data) {
                    // app.warning("", data);
                    $("#spinner").hide();
                    this.removeFile(file);
                },
                removedfile: function (file) {
                    $("#spinner").show();
                    $.delete(app.baseUrl + "/product/" + idProduct + "/product_media/" + file.id)
                        .done(function (data) {
                            $("#spinner").hide();

                            if (data.response) {
                                $(document).find(file.previewElement).remove();
                            } else {
                                app.warning("", data.message);
                            }
                        })
                        .fail(function () {
                            $("#spinner").hide();
                            app.error("", "Server error! Retry later!");
                        });
                },
                maxfilesexceeded: function (file) {
                    app.warning("", T.uploadMaxFilesExceeded);
                    this.removeFile(file);
                }
            });
        }

        if ($("#featured-image").length > 0) {
            // c'è l'elemento e allora gli applico il DropZone sopra
            $("#featured-image").dropzone({
                url: app.baseUrl + "/product/" + idProduct + "/product_media?featured=1",
                headers: $.ajaxSetup().headers,
                addRemoveLinks: true,
                dictRemoveFile: $("#featured-image").attr('data-remove'),
                dictRemoveFileConfirmation: T.uploadRemove,
                maxFiles: 1,
                clickable: true,
                maxFilesize: $("#featured-image").attr('data-max'),
                acceptedFiles: 'image/*',
                init: function () {
                    var medias = JSON.parse($("#featured-image-media").val());

                    if (typeof medias.ProductMedias !== 'undefined' && medias.ProductMedias.length > 0) {
                        medias = medias.ProductMedias;

                        var self = this;
                        $.each(medias, function () {
                            if (typeof this.Id !== 'undefined') {
                                // oggetto file per dropzone
                                var file = {
                                    name: this.Image,
                                    size: this.Size,
                                    status: 'success',
                                    id: this.Id,
                                    accepted: true
                                };

                                // fingo di aggiungere un nuovo documento a dropzone
                                self.emit("addedfile", file);
                                self.createThumbnailFromUrl(file, app.siteUrlNotLocalized + "/storage/" + this.Image);
                                self.emit("complete", file);

                                // aggiungo il mio file all'array
                                self.files.push(file);
                            }
                        });
                    }
                },
                sending: function (file, data) {
                    $("#spinner").show();
                },
                success: function (file, data) {
                    $("#spinner").hide();
                    if (!data.response) {
                        app.warning("", data.message);
                        this.removeFile(file);
                    } else {
                        file.id = data.message;
                    }
                },
                error: function (file, data) {
                    // app.warning("", data);
                    $("#spinner").hide();
                    this.removeFile(file);
                },
                removedfile: function (file) {
                    $("#spinner").show();
                    $.delete(app.baseUrl + "/product/" + idProduct + "/product_media/" + file.id)
                        .done(function (data) {
                            $("#spinner").hide();

                            if (data.response) {
                                $(document).find(file.previewElement).remove();
                            } else {
                                app.warning("", data.message);
                            }
                        })
                        .fail(function () {
                            $("#spinner").hide();
                            app.error("", "Server error! Retry later!");
                        });
                },
                maxfilesexceeded: function (file) {
                    app.warning("", T.uploadMaxFilesExceeded);
                    this.removeFile(file);
                }
            });
        }

        if ($(".products").length > 0) {
            this.bindDraggable($(".draggable"));

            $(".droppable").sortable({
                items: '.draggable',
                over: function (event, ui) {
                    $(this).find('.droppable-label').hide();
                },
                receive: function (event, ui) {
                    var self = $(this);
                    var id = ui.helper.attr("data-id");
                    var type = self.attr('data-type');

                    // nascondo la label, poi al massimo la rimetto
                    self.find('.droppable-label').hide();
                    // nascondo l'elemento originale
                    ui.item.hide();

                    $(".spinner").show();
                    $.post(app.baseUrl + "/product/" + idProduct + "/product_matching", {
                        id_linked_product: id,
                        type: type
                    }).done(function (data) {
                            if (data.response) {
                                // faccio comparire la x per la cancellazione
                                ui.helper.find('.draggable-delete').removeClass('hidden');

                                // inserisco l'id dell'elemento appena inserito nel bottone di eliminazione
                                ui.helper.find('[data-interaction=delete-product]').attr('data-id', data.message);

                                product.bindDeleteProduct(ui.helper.find('.draggable-delete'));

                                // rimuovo l'elemento originale
                                ui.item.remove();
                            } else {
                                app.warning("", data.message);

                                // rivisualizzo la label se non ho elementi
                                if (self.find('.draggable').length === 0)
                                    self.find('.droppable-label').show();
                                // visualizzo l'elemento originale
                                ui.item.show();
                                // rimuovo il clone
                                ui.helper.remove();
                            }

                            $(".spinner").hide();
                        })
                        .fail(function (data) {
                            $(".spinner").hide();
                            app.error("", app.parseAjaxError(data));

                            // rivisualizzo la label se non ho elementi
                            if (self.find('.draggable').length === 0)
                                self.find('.droppable-label').show();
                            // visualizzo l'elemento originale
                            ui.item.show();
                            // rimuovo il clone
                            ui.helper.remove();
                        });
                }
            });
            $(".droppable").droppable({
                accept: '.draggable',
                hoverClass: 'droppable-hover'
            });

            product.bindDeleteProduct($(".droppable .draggable-delete"));
        }

        if ($("#email-image").length > 0) {
            // c'è l'elemento e allora gli applico il DropZone sopra
            $("#email-image").dropzone({
                url: app.baseUrl + "/product/" + idProduct + "/email_image",
                headers: $.ajaxSetup().headers,
                addRemoveLinks: true,
                dictRemoveFile: $("#email-image").attr('data-remove'),
                dictRemoveFileConfirmation: T.uploadRemove,
                maxFiles: 1,
                clickable: true,
                maxFilesize: $("#email-image").attr('data-max'),
                acceptedFiles: 'image/*',
                init: function () {
                    var id = $("#email-image").attr('data-id');
                    var size = $("#email-image").attr('data-size');
                    var name = $("#email-image").attr('data-name');

                    var self = this;
                    if($.trim(size) !== '0') {
                        // oggetto file per dropzone
                        var file = {
                            name: name,
                            size: size,
                            status: 'success',
                            accepted: true,
                            id: id,
                        };

                        // fingo di aggiungere un nuovo documento a dropzone
                        self.emit("addedfile", file);
                        self.createThumbnailFromUrl(file, app.siteUrlNotLocalized + "/storage/" + name);
                        self.emit("complete", file);

                        // aggiungo il mio file all'array
                        self.files.push(file);
                    }
                },
                sending: function (file, data) {
                    $("#spinner").show();
                },
                success: function (file, data) {
                    $("#spinner").hide();
                    if (!data.response) {
                        app.warning("", data.message);
                        this.removeFile(file);
                    } else {
                        file.id = data.message;
                        app.success("", data.message);
                    }
                },
                error: function (file, data) {
                    // app.warning("", data);
                    $("#spinner").hide();
                    this.removeFile(file);
                },
                removedfile: function (file) {
                    $("#spinner").show();
                    $.delete(app.baseUrl + "/product/" + idProduct + "/email_image")
                        .done(function (data) {
                            $("#spinner").hide();

                            if (data.response) {
                                $(document).find(file.previewElement).remove();
                            } else {
                                app.warning("", data.message);
                            }
                        })
                        .fail(function () {
                            $("#spinner").hide();
                            app.error("", "Server error! Retry later!");
                        });
                },
                maxfilesexceeded: function (file) {
                    app.warning("", T.uploadMaxFilesExceeded);
                    this.removeFile(file);
                }
            });
        }

        if ($("#price").length > 0) {
            $.get(app.baseUrl + "/product/" + idProduct + "/price")
                .done(function (data) {
                    $("#price").html(data);
                })
                .fail(function (data) {
                    app.error(app.parseAjaxError(data));
                });
        }

        $("a[href='#price']").on("click", function () {
            $("#price").find("table").data("dataTable").redrawPage();
        });

        if ($("#percentage").length > 0) {
            $.get(app.baseUrl + "/product/" + idProduct + "/experience_price_percentage")
                .done(function (data) {
                    $("#percentage").html(data);
                });
        }

        $("a[href='#percentage']").on("click", function () {
            $("#percentage").find("table").data("dataTable").redrawPage();
        });

        if ($("#variant").length > 0) {
            $.get(app.baseUrl + "/product/" + idProduct + "/product_variant")
                .done(function (data) {
                    $("#variant").html(data);
                });
        }

        $("a[href='#variant']").on("click", function () {
            $("#variant").find("table").data("dataTable").redrawPage();
        });

        if ($("#notification").length > 0) {
            $.get(app.baseUrl + "/product/" + idProduct + "/product_notification")
                .done(function (data) {
                    $("#notification").html(data);
                });
        }

        if ($("#products.automatic").length > 0) {
            $.get(app.baseUrl + "/product/" + idProduct + "/product_matching")
                .done(function (data) {
                    $("#products.automatic").html(data);
                });
        }

        $("a[href='#notification']").on("click", function () {
            $("#notification").find("table").data("dataTable").redrawPage();
        });

        if ($("#customer-type-exclusion").length > 0) {
            $.get(app.baseUrl + "/product/" + idProduct + "/product_customer_type_exclusion")
                .done(function (data) {
                    $("#customer-type-exclusion").html(data);
                });
        }

        $("a[href='#customer-type-exclusion']").on("click", function () {
            $("#customer-type-exclusion").find("table").data("dataTable").redrawPage();
        });

        if ($("#automatic-percentage").length > 0) {
            $.get(app.baseUrl + "/product/" + idProduct + "/product_automatic_percentage")
                .done(function (data) {
                    $("#automatic-percentage").html(data);
                });
        }

        if ($("#product-code").length > 0) {
            $.get(app.baseUrl + "/product/" + idProduct + "/product_code")
                .done(function (data) {
                    $("#product-code").html(data);
                });
        }

        $("a[href='#automatic-percentage']").on("click", function () {
            $("#automatic-percentage").find("table").data("dataTable").redrawPage();
        });
    };

    this.bindDraggable = function ($node) {
        $node.draggable({
            revert: 'invalid',
            opacity: 0.75,
            helper: 'clone',
            connectToSortable: '.droppable',
            stop: function (event, ui) {
                // se non ho più elementi draggable visualizzo nuovamente la label
                if ($('.droppable').find('.draggable').length === 0)
                    $('.droppable').find('.droppable-label').show();

                $('.droppable').removeClass('droppable-hover');
            }
        });
    };

    this.bindDeleteProduct = function ($node) {
        $node.unbind('click').bind('click', function () {
            // recupero idProduct e idLinkedProduct
            var self = $(this);
            var idProduct = $("#frmProduct").attr("data-id");
            var idLinkedProduct = $(this).find('[data-interaction=delete-product]').attr("data-id");

            $(".spinner").show();
            $.delete(app.baseUrl + '/product/' + idProduct + '/product_matching/' + idLinkedProduct)
                .done(function (data) {
                    if (data.response) {
                        // faccio un clone di questo elemento draggable
                        var $clone = self.closest('.draggable').clone();
                        // rimuovo questo elemento
                        self.closest('.draggable').remove();
                        // sul clone disabilito il tasto per rimuovere il prodotto
                        $clone.find(".draggable-delete").addClass('hidden');
                        // aggiungo il clone alla lista dei prodotti disponibili
                        $(".draggable-container").append($clone);
                        // faccio il bindDraggable così da far diventare il clone un oggetto draggable
                        product.bindDraggable($clone);

                        // se non ho più elementi draggable visualizzo nuovamente la label
                        if ($('.droppable').find('.draggable').length === 0)
                            $('.droppable').find('.droppable-label').show();
                    } else {
                        app.warning("", data.message);
                    }

                    $(".spinner").hide();
                })
                .fail(function (data) {
                    $(".spinner").hide();
                    app.error("", app.parseAjaxError(data));
                });
        });
    };

};
