var shop = function () {
    var shop = this;

    this.bindEvents = function (shopCrud) {
        // var idShop = $("#frmShop").attr("data-id");
        // var logo = $("#logo").dropzone({
        //     autoProcessQueue: false, // evita di fare immediatamente il caricamento delle immagini
        //     url: "#",
        //     headers: $.ajaxSetup().headers,
        //     addRemoveLinks: true,
        //     dictRemoveFile: $("#logo").attr('data-remove'),
        //     dictRemoveFileConfirmation: T.uploadRemove,
        //     maxFiles: 1,
        //     maxFilesize: $("#logo").attr('data-max'),
        //     acceptedFiles: 'image/*',
        //     init: function () {
        //         var size = $("#logo").attr('data-size');
        //         var name = $("#logo").attr('data-name');
        //
        //         var self = this;
        //         if ($.trim(size) != "") {
        //             // oggetto file per dropzone
        //             var file = {
        //                 name: name,
        //                 size: size,
        //                 status: 'success',
        //                 accepted: true
        //             };
        //
        //             // fingo di aggiungere un nuovo documento a dropzone
        //             self.emit("addedfile", file);
        //             self.createThumbnailFromUrl(file, app.siteUrl + "/storage/shop/logo/" + name);
        //             self.emit("complete", file);
        //
        //             // aggiungo il mio file all'array
        //             self.files.push(file);
        //         }
        //     },
        //     sending: function (file, data) {
        //         $("#spinner").show();
        //     },
        //     success: function (file, data) {
        //         $("#spinner").hide();
        //         if (!data.response) {
        //             app.warning("", data.message);
        //             this.removeFile(file);
        //         }
        //     },
        //     error: function (file, data) {
        //         app.warning("", data);
        //         $("#spinner").hide();
        //         this.removeFile(file);
        //     },
        //     removedfile: function (file) {
        //         if (file.status == "success") {
        //             $("#spinner").show();
        //             $.put(app.baseUrl + "/shop/" + idShop, {op: 'inline', logo_size: 0})
        //                 .done(function (data) {
        //                     $("#spinner").hide();
        //
        //                     if (data.response) {
        //                         $(document).find(file.previewElement).remove();
        //                     } else {
        //                         app.warning("", data.message);
        //                     }
        //                 })
        //                 .fail(function () {
        //                     $("#spinner").hide();
        //                     app.error("", "Server error! Retry later!");
        //                 });
        //         } else {
        //             $(document).find(file.previewElement).remove();
        //         }
        //     },
        //     maxfilesexceeded: function (file) {
        //         app.warning("", "You can upload max 1 image");
        //         this.removeFile(file);
        //     }
        // });

        // shopCrud.getCustomFormData = function (data) {
        //     // aggiungo il file inserito tramite dropzone
        //     for (var i = 0; i < logo[0].dropzone.files.length; i++) {
        //         if (logo[0].dropzone.files[i].status === 'queued') {
        //             data.append('logo', logo[0].dropzone.files[i], logo[0].dropzone.files[i].name);
        //         }
        //     }
        //
        //     return data;
        // };
    };
};