var user = function(dt) {

    this.bindEvents = function() {
        $("[data-interaction=reset]").unbind('click').bind('click', function () {
            if(confirm("Inviare il link per il reset della password?")) {
                var url = $(this).attr("data-url");

                app.block(1);
                $.post(url)
                    .success(function (data) {
                        if(data.response) {
                            app.success(data.message);
                        } else {
                            app.warning("", data.message);
                        }
                        app.block(0);
                    })
                    .error(function () {
                        app.block(0);
                        app.error("", "Ajax error!");
                    });
            }
        });

        $('[data-interaction=login_as]').unbind('click').bind('click', function () {
            app.loginAs(this);
        });
    };

    this.bindEvents();

};
