var order = function(step){

    this.current_step = step || 0;

    var self = this;

    this.bindDetailEvents = function (locale) {

        $('.slick').slick({
            // dots: false,
            // infinite: true,
            // speed: 300,
            // slidesToShow: 3,
            // centerMode: true,
            variableWidth: true
        });

        // recupero la data
        var date      = $("#product-date").val();
        var idProduct = $("[data-step="+self.current_step+"]").find("#id_product").val();

        // nascondo il div dei prezzi
        $("#product-price").hide();

        if ($.trim(date) !== 'undefined') {
            // mostro il loader
            $(".spinner").show();

            // faccio una chiamata ajax che mi tornerà una form da inserire in #product-price
            $.get(app.baseUrl + "/wizard/price")
                .done(function (data) {
                    $("#product-price").html(data);
                    $(".spinner").hide();
                    $("#product-price").show();
                })
                .fail(function (data) {
                    $("#product-price").html('<h2 class="text-center">' + data.responseJSON.message + '</h2>');
                    $(".spinner").hide();
                    $("#product-price").show();
                });
        }
    };

    this.bindPriceBoxEvents = function () {
        $("#school_contract").unbind("change").bind("change", function () {
            self.updateTotalPrice();
        });

        $('.btn-number').click(function (e) {
            e.preventDefault();

            var fieldName  = $(this).attr('data-field');
            var type       = $(this).attr('data-type');
            var input      = $("input[name='" + fieldName + "']");
            var currentVal = parseInt(input.val());
            if (!isNaN(currentVal)) {
                if (type === 'minus' && currentVal !== 0) {
                    input.val(currentVal - 1).change();
                    if (parseInt(input.val()) === 0) {
                        $(this).attr('disabled', true);
                    }

                } else if (type === 'plus') {
                    input.val(currentVal + 1).change();
                }
            } else {
                input.val(0);
            }
        });
        $('.input-number').focusin(function () {
            $(this).data('oldValue', $(this).val());
        });
        $(".input-number").keydown(function (e) {
            // Allow: backspace, delete, tab, escape, enter and .
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode == 65 && e.ctrlKey === true) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });
    };

    this.bindPriceEvents = function () {
        this.bindPriceBoxEvents();

        $('.product-price').find('.input-number').change(function () {

            var minValue     = 0;
            var valueCurrent = parseInt($(this).val());
            var handicap = $(this).attr('data-handicap');

            if (handicap) {
                // se ho almeno un handicap sblocco gli accompagnatori
                let unlock = false;
                $('[data-handicap=1]').each(function () {
                    if (parseInt($(this).val()) > 0)
                        unlock = true;
                });

                if (unlock) {
                    // abilitare data-companion=1
                    $('[data-companion=1]').prop('disabled', false);
                } else {
                    // disabilitare data-companion=1
                    $('[data-companion=1]').prop('disabled', true);
                    $('input[data-companion=1]').val(0);
                }
            }

            var name = $(this).attr('name');
            if (valueCurrent >= minValue) {
                $(".btn-number[data-type='minus'][data-field='" + name + "']").removeAttr('disabled')
            } else {
                alert($(this).closest('.price-box').attr('data-warn'));
                $(this).val($(this).data('oldValue'));
            }

            self.updateTotalPrice();
        });

        $('.product-price-handicap').on('change', function () {
            if ($(this).is(':checked')) {
                $('.price-box.handicap').removeClass('hidden');
            } else {
                $('.price-box.handicap').find('.input-number').val(0);
                $('.price-box.handicap').addClass('hidden');
                self.updateTotalPrice();
            }
        });

        self.updateTotalPrice();
    };

    this.updateTotalPrice = function () {

        $("[data-interaction=next]").prop("disabled",false);
        var idProduct = $("[data-step="+self.current_step+"]").find("#id_product").val();
        // recupero le informazioni che ho all'interno della form
        var data = new FormData($('.product-price')[0]);

        var date;
        if($("[data-step="+self.current_step+"]").find("#date-visit").length > 0)
            date = $("[data-step="+self.current_step+"]").find("#date-visit").val();
        else
            date = $("[data-step="+self.current_step+"]").find("[name=date]").val();

        data.append("date", date);
        data.append('total', true);

        var dates = [];
        $(".datepicker").each(function () {
            dates.push({
                id_product: $(this).attr('data-id-product'),
                product_label: $(this).attr('data-product-label'),
                date: $(this).val()
            });
        });
        data.append('dates', JSON.stringify(dates));

        $.ajax({
            url        : app.baseUrl + '/wizard/' + idProduct + '/session',
            data       : data,
            processData: false,
            contentType: false,
            type       : 'post'
            }).done(function (data) {

                if (typeof data.price != "undefined") {
                    var price_array = data.price;
                    var tmp_price = "";
                    $.each(price_array, function (i, product_price_array) {
                        $.each(product_price_array, function (j, price_ct) {
                            $.each(price_ct, function (price_key, price_val) {
                                tmp_price = $("#price_"+i+"_"+price_key).html();
                                if (tmp_price != price_val)
                                    $("#price_"+i+"_"+price_key).html(price_val);
                            });

                        });
                    });
                }


                var total = parseFloat(data.total);


                if (data.handicap && total < 0)
                    total = 0;

                if (total >= 0) {

                    $('.product-price-total').html("<div class='col-sm-6 text-left'>"+$('.product-price-total').attr("data-prefix")+"</div><div class='col-sm-6 text-right'>"+total.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + "€"+"</div>");

                    var discount = data.discount;
                    if (discount.count !== 0 ) {
                        // ho degli sconti per cui li mostro assieme agli importi
                        $('.product-price-discount').removeClass('text-center');
                        $('.product-price-discount').html('<h3 class="text-center text-uppercase" style="margin-bottom: 10px; margin-top: 10px;">' + $('.product-price-discount').attr('data-title') + '</h3>');
                        $.each(discount.amounts, function () {
                            var amount = parseFloat(this.amount).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + "€";
                            $('.product-price-discount').append('<div class="row"><div class="col-xs-9">' + this.label + '</div><div class="col-xs-3 text-right"><b>-' + amount + '</b></div></div>');
                        });
                    }
                    else
                    {
                        $('.product-price-discount').html("");
                    }

                    var tax = data.tax;
                    if (tax.length > 0) {
                        // ho delle tasse da mostrare
                        $('.product-price-tax').html('<h3 class="text-center text-uppercase" style="margin-bottom: 10px; margin-top: 10px;">' + $('.product-price-tax').attr('data-title') + '</h3>');
                        $.each(tax, function () {
                            var amount = parseFloat(this.amount).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + "€";
                            $('.product-price-tax').append('<div class="row"><div class="col-xs-9">' + this.label + '</div><div class="col-xs-3 text-right"><b>' + amount + '</b></div></div>');
                        });
                    }
                    else
                    {
                        $('.product-price-tax').html("");
                    }




                } else {
                    $('.product-price-total').html($('.product-price-total').attr('data-invalid'));
                    $('.product-price-discount').addClass('text-center');
                    var error = typeof data.error !== 'undefined' ? data.error : $('.product-price-discount').attr('data-empty');
                    $('.product-price-discount').html(error);
                    $("[data-interaction=next]").prop("disabled",true);
                }

                if (total > 0 || data.handicap) {
                    $('.product-price-submit').show();
                } else {
                    $('.product-price-submit').hide();
                }
            });

    };

    this.updateTotalExtra = function () {
        // recupero l'id del prodotto
        var idProduct = $("[data-step="+self.current_step+"]").find("#id_product").val();
        // recupero le informazioni che ho all'interno della form
        var data = new FormData($('.product-extra')[0]);

        // $(".spinner").show();
        $.ajax({
            url        : app.baseUrl + '/wizard/' + idProduct + '/session',
            data       : data,
            processData: false,
            contentType: false,
            type       : 'post'
        })
            .done(function (data) {
                $("#product-price-extra").html(data);
                // $(".spinner").hide();
            })
            .fail(function (data) {
                $("#product-price-extra").html('<h2 class="text-center">' + data.responseJSON.message + '</h2>');
                // $(".spinner").hide();
            });
    };

    this.bindExtraEvents = function () {
        this.bindPriceBoxEvents();
        $('.extra').find('.extra-box:not(.extra-tour)').find('.input-number').change( function () {

            var minValue     = 0;
            var valueCurrent = parseInt($(this).val());
            var name = $(this).attr('name');

            if (valueCurrent >= minValue) {
                $(".btn-number[data-type='minus'][data-field='" + name + "']").removeAttr('disabled')
            } else {
                alert($(this).closest('.price-box').attr('data-warn'));
                $(this).val($(this).data('oldValue'));
            }

            self.updateTotalExtra();
        });

        $('[data-interaction=equal]').unbind('click').bind('click', function () {
            var group = $(this).attr('data-group');

            $('[data-group=' + group + ']').each(function () {
                $(this).val($(this).attr('data-n'));
            });

            if ($(this).attr('data-type') === 'extra')
                self.updateTotalExtra();
            else
                $('[data-group=' + group + ']').trigger('change');
        });

        self.updateTotalExtra();
    };


    this.bindTourEvents = function (locale) {
        // recupero l'id del prodotto
        var idProduct = $("[data-step="+self.current_step+"]").find("#id_product").val();
        // recupero l'array dei giorni di disponibilità
        var days = JSON.parse($("#days").val());

        if (Object.keys(days).length > 0) {
            $('.extra-tour').each(function () {
                var self = this;
                var location = $(this).find(".tour-location").val();

                // $(this).find('.datetimepicker').datetimepicker({
                //     dayOfWeekStart: 1,
                //     format: app.date_format,
                //     closeOnDateSelect: true,
                //     timepicker: false,
                //     scrollMonth: false,
                //     scrollInput: false,
                // });

                $(this).find('[data-interaction=check]').unbind("click").bind("click", function () {

                    var number = $(self).find(".tour-number").val();
                    var id = $(self).find(".tour-id").val();

                    // TODO: validation
                    if(number > 0)
                    {
                        $(self).find('.tour-detail').html($(".spinner").clone());
                        $(self).find('.tour-detail').find(".spinner").show();
                        $.post(app.baseUrl + '/wizard/' + idProduct + '/session', {
                            is_tour: true,
                            action: 'remove',
                            id: id
                        }).done(function (data) {
                            $("#product-price-extra").html(data);
                            var date = $(self).find('.date').val();
                            $.post(app.baseUrl + "/wizard/" + idProduct + "/slots", {
                                id: id,
                                location: location,
                                date: date,
                                number: number
                            }).done(function (data) {
                                $(self).find('.tour-detail').html(data);
                            }).fail(function (data) {
                                $(self).find('.tour-detail').html('<h2 class="text-center">' + data.responseJSON.message + '</h2>');
                            });
                        });
                    }
                });

                $(this).find('.theme').on('change', function () {
                    // ho cambiato il tema, scateno il tasto controlla disponibilità così da resettare le scelte fatte
                    $(this).closest(".tour-actions").find('[data-interaction=check]').trigger('click');
                });
            });
        }

        $('.extra').find('.extra-tour').find('.input-number').unbind('change').bind('change', function () {

            var minValue     = 0;
            var valueCurrent = parseInt($(this).val());
            var name = $(this).attr('name');

            if (valueCurrent >= minValue) {
                $(".btn-number[data-type='minus'][data-field='" + name + "']").removeAttr('disabled')
            } else {
                alert($(this).closest('.price-box').attr('data-warn'));
                $(this).val($(this).data('oldValue'));
            }

            if (valueCurrent > 0) {
                $(this).closest('.extra-tour').find('.tour-actions').show();
            } else {
                var id = $(this).closest('.extra-tour').find(".tour-id").val();
                var self = this;

                $(".product-date-price").find(".spinner").show();

                $(this).closest('.extra-tour').find('.tour-detail').html($(".spinner").clone());
                $(this).closest('.extra-tour').find('.tour-detail').find(".spinner").show();

                $.post(app.baseUrl + '/wizard/' + idProduct + '/session', {
                    is_tour: true,
                    action: 'remove',
                    id: id
                }).done(function (data) {
                    $("#product-price-extra").html(data);
                    $(".product-date-price").find(".spinner").hide();

                    // nascondo

                    $(self).closest('.extra-tour').find('.tour-detail').html("");


                }).fail(function (data) {
                    $(self).closest('.extra-tour').find('.tour-detail').html('<h2 class="text-center">' + data.responseJSON.message + '</h2>');

                    $(self).closest('.extra-tour').find('.tour-actions').hide();
                    $(".product-date-price").find(".spinner").hide();
                });
            }
        });
    };

    this.unlock = function (ids) {
        if (ids.length === 0) {
            app.warning("Selezionare almeno un elemento");
            return;
        }

        $("#modalEmail").find("#send").unbind("click").bind("click", function () {
            if (!confirm("TOTO MESSAGGIO"))
                return;

            app.block(1);
            $.post(app.baseUrl + "/order/unlock", {
                ids: ids,
                date: $("#date").val(),
                subject: $("#subject").val(),
                text: $("#text").val()
            }).done(function (data) {
                app.block(0);
                if (data.response) {
                    app.success(data.message);
                    $("#modalEmail").modal("hide");
                    app.reload();
                } else {
                    app.warning(data.message);
                }
            }).fail(function (data) {
                app.block(0);
                app.error(app.parseAjaxError(data));
            });
        });

        $("#modalEmail").modal("show");
    };

    this.unlockAll = function () {
        this.unlock([-1]);
    };

};