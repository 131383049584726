var calendar = function () {

    var calendar = this;

    this.getUrl = function () {
        return "";
    };

    this.eventAfterRender = function (calEvent, $node) {};

    this.eventClick = function (calEvent, jsEvent, view) {};

    this.bindEvents = function (selector) {
        $(selector).fullCalendar({
            defaultView: 'month',
            header: {},
            eventSources: [
                {
                    url: calendar.getUrl()
                }
            ],
            eventAfterRender: function (calEvent, $node) {
                calendar.eventAfterRender(calEvent, $node);
            },
            eventClick: function (calEvent, jsEvent, view) {
                calendar.eventClick(calEvent, jsEvent, view);
            }
        });
    };

};