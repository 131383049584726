var code = function () {

    var code = this;

    this.bindEvents = function () {
        var idCode = $("#frmCode").attr("data-id");

        if ($("#category_code").length > 0) {
            $.get(app.baseUrl + "/code/" + idCode + "/category_code")
                .done(function (data) {
                    $("#category_code").html(data);
                });
        }

        if ($("#user_code").length > 0) {
            $.get(app.baseUrl + "/code/" + idCode + "/user_code")
                .done(function (data) {
                    $("#user_code").html(data);
                });
        }

        if ($("#device_code").length > 0) {
            $.get(app.baseUrl + "/code/" + idCode + "/device_code")
                .done(function (data) {
                    $("#device_code").html(data);
                });
        }
    };

};