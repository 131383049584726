var wizard = function (step)
{
    var wizard = this;

    this.initialStep = step || 1;
    this.currentStep = undefined;
    this.hasForm = 0;
    this.last = false;

    this.init = function () {
        $("#wizard").wizard();
        $("#wizard").wizard('selectedItem', {
            step: wizard.initialStep
        });

        this.changeStep();

        this.bindEvents();
    };

    this.manageResponse = function (data) {
        wizard.currentStep = data.step;
        wizard.last = data.last;

        if (data.disableBack)
            $('[data-interaction=back]').attr('disabled', 'disabled');
        else
            $('[data-interaction=back]').removeAttr('disabled');

        if (data.disableNext)
            $('[data-interaction=next]').attr('disabled', 'disabled');
        else
            $('[data-interaction=next]').removeAttr('disabled');

        if (data.hideBack)
            $('[data-interaction=back]').hide();
        else
            $('[data-interaction=back]').show();

        if (data.hideNext)
            $('[data-interaction=next]').hide();
        else
            $('[data-interaction=next]').show();

        $("[data-interaction=next]").find('span').html(data.next);
        $("[data-interaction=back]").find('span').html(data.back);
    };

    this.getView = function (direction) {
        // get new view
        $("#spinner").show();

        // nel caso io sia sull'ultimo step vado all'homepage


        var step = wizard.currentStep;
        if(step == null)
        {
            step = wizard.initialStep;
        }

        var shoppingCart = [];

        if (direction !== 'previous') {
            switch (wizard.currentStep) {
                case 1:
                   shoppingCart = {
                       "id_product" : $("#id_product").val()
                   };
                    break;
                case 2:

                    break;
                case 4:
                    var notes = $("[data-step="+wizard.currentStep+"]").find("[name=notes]").val();
                    var reference = $("[data-step="+wizard.currentStep+"]").find("[name=reference]").val();
                    var ipa_ft_pa = $("[data-step="+wizard.currentStep+"]").find("[name=ipa_ft_pa]").val();
                    var cup_ft_pa = $("[data-step="+wizard.currentStep+"]").find("[name=cup_ft_pa]").val();
                    var cig_ft_pa = $("[data-step="+wizard.currentStep+"]").find("[name=cig_ft_pa]").val();

                    shoppingCart = {
                        "reference" : reference,
                        "notes" : notes,
                        "ipa_ft_pa" : ipa_ft_pa,
                        "cup_ft_pa" : cup_ft_pa,
                        "cig_ft_pa" : cig_ft_pa
                    };
                    break;
            }
            if (wizard.last) {

                var reciver_name="";
                var reciver_mail="";
                if($("#reciver_name").length > 0)
                {
                    reciver_name = $("#reciver_name").val();
                }

                if($("#reciver_mail").length > 0)
                {
                    reciver_mail = $("#reciver_mail").val();
                }

                app.block(1);
                $.post(app.baseUrl+"/wizard/create_order",{reciver_name : reciver_name, reciver_mail : reciver_mail})
                    .done(function (data) {
                        app.block(0);
                        app.href("order");
                    })
                    .error(function(){
                        app.block(0);
                    });
                $("#spinner").hide();

                return;
            }
        }

        $.post(app.baseUrl + "/wizard", {direction: direction, step: step, shopping_cart_gold:shoppingCart})
            .done(function (data) {

                if (data.response) {
                    wizard.manageResponse(data);
                    $.get(data.url, {direction : direction})
                        .done(function (data) {
                            wizard.hasForm = 0;
                            var $target = $(".step-content").find('[data-step="' + wizard.currentStep + '"]').find(".content");

                            //Fix per la rimozione dello step extra nei biglietti prepagati
                            var stp = wizard.currentStep;
                            var prepaid_ticket = $("#prepaid_ticket").length > 0 ? $("#prepaid_ticket").val() : false;
                            if (prepaid_ticket && wizard.currentStep == 4)
                            {
                                 if(direction == "next" || direction == null)
                                     stp = 3;
                                 else
                                    stp = 2;
                            }
                            // sposto il wizard e metto a posto i pulsanti
                            $("#wizard").wizard('selectedItem', {
                                step: stp//wizard.currentStep
                            });

                            $target.html(data);

                            app.runBind();

                            if ($target.find('form').length > 0) {
                                // ho una form per inviare i dati
                                wizard.$form = $target.find('form');
                                wizard.hasForm = 1;
                            }

                            switch (wizard.currentStep) {
                                case 1:
                                    // TODO: disabilitare tramite logica controller
                                    // $("[data-interaction=wizardBtnBack]").attr("disabled", "disabled");
                                    $("[data-interaction=wizardBtnNext]").removeAttr("disabled");
                                    break;
                                default:
                                    $("[data-interaction=wizardBtnBack]").removeAttr("disabled");
                                    $("[data-interaction=wizardBtnNext]").removeAttr("disabled");
                            }

                            $("#spinner").hide();
                        })
                        .fail(function () {
                            $("#spinner").hide();
                        });
                } else {
                    // TODO: gestire errore
                    $("#spinner").hide();
                }
            })
            .fail(function () {
                $("#spinner").hide();
            });
    };

    this.changeStep = function (direction, skip)
    {
        if (direction !== 'previous' && !skip) {

            switch(wizard.currentStep)
            {
                case 1:

                    break;
                case 2:
                    //controlla che esistano dei prezzi per il prodotto selezionato
                    if($("[data-step=2]").find(".product-price").find("[name*='main']").length == 0)
                    {
                        app.error("","Nessun prezzo disponibile");
                        return;
                    }

                    break;
                case 3:
                    var ret = false;
                    $('.extra').find('.extra-tour').each(function () {
                        var number = parseInt($(this).find(".tour-number").val());

                        /*if (number > 0) {
                            var checked = parseInt($(this).find("[data-interaction=webooking-code]").attr("data-checked"));
                            if (!checked) {
                                alert("Prima di procedere devi convalidare il codice per la visita guidata");
                                throw "...";
                            }
                        }*/

                        var slotSelected = $(this).find('.slots-detail').find('.selected').length > 0;

                        if (number > 0 && !slotSelected) {
                            ret = true;
                        }
                    });
                    if(ret)
                    {
                        alert("Selezionare un'orario per la visita guidata");
                        return;
                    }

                    break;
            }

            // controllo se ho dataTable con righe in stato non salvato
            var notSaved = false;
            var $tr;
            var $table = $(".step-content").find('[data-step="' + wizard.currentStep + '"]').find(".content").find('table');
            $table.find('tr').each(function () {
                if ($(this).attr('data-add-client')) {
                    $tr = $(this);
                    notSaved = true;
                }
            });

            if (notSaved) {
                $table.unbind('dataTable.add_client.done').bind('dataTable.add_client.done', function () {
                    wizard.changeStep(direction, skip);
                });

                $tr.find('[data-interaction="edit_default"]').trigger('click');
                return;
            } else if (wizard.hasForm) {

            }


        }

        wizard.getView(direction);
    };

    this.bindEvents = function ()
    {
        $("#wizard").on("stepclicked.fu.wizard", function (e, obj) {
            e.preventDefault();
        });

        $("#wizard").on("actionclicked.fu.wizard", function (e, obj) {
            wizard.changeStep(obj.direction);

            e.preventDefault();
        });

        $('[data-interaction=next]').unbind('click').bind('click', function () {

            $("#wizard").wizard('next');
        });

        $('[data-interaction=back]').unbind('click').bind('click', function () {
            $("#wizard").wizard('previous');

            if (wizard.currentStep == 1)
                wizard.changeStep('previous');
        });

        $('[data-interaction=logout]').unbind('click').bind('click', function () {
            app.block(1);
            $.post(app.baseUrl+"/wizard/stop_wizard")
                .done(function (data) {
                    app.block(0);
                    if(data.response)
                    {
                        app.href("order");
                    }
                    else
                        app.warning("",data.message);
                })
                .error(function () {
                    app.block(0);
                });
        });
    };
};

